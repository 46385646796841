import React from 'react';
import styled from 'styled-components';
import iconGreenImage from '../assets/db_green_icon.png';
import iconBlueImage from '../assets/db_blue_icon.png';
import iconDarkImage from '../assets/db_dark_icon.png';
import useIntersectionObserver from "./Hook";

const MainContainer = styled.div`
  display: flex;
    flex-wrap: wrap;
  align-items: center;
    background-color: #F0F0F5;
  height: 100%;
    min-height: 100vh;
  position: relative;
  font-family: 'Poppins', Arial, sans-serif;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
  
  @media (max-width: 1325px) {
    padding-bottom: 100px;
  }
  
  @media (max-width: 768px) {
      padding-top: 100px;
    padding-bottom: 100px;
    flex-direction: column; /* Stack image on top when screen is smaller */
  }
`;

const InitContainer = styled.div`
  flex: 1 1 500px; /* This ensures the items have a min width of 600px */
  max-width: 300px;
  max-height: 200px;
  margin-top: -210px;
  border-left: 1px solid lightgray;
  padding-left: 100px;
  padding-right: 40px;

  @media (max-width: 1325px) {
    margin-top: 100px;
    padding-left: 50px;
    margin-right: 750px;
  }

  @media (max-width: 768px) {
    margin-top: 100px;
    margin-right: 0;
    padding-bottom: 50px;
    padding-right: 20px;
    padding-left: 20px;
    border-left: 0 solid lightgray;
    flex: 1 1 100%; /* 1 column */
  }
`;

const TextContainer = styled.div`
  flex: 1 1 100px; /* This ensures the items have a min width of 600px */
  min-width: 100px;
  border-left: 1px solid lightgray;
  padding-left: 50px;
  padding-right: 50px;
  
  @media (max-width: 1325px) {
    min-width: 100px;
    padding-left: -50px;
    padding-right: 100px;
  }
  
  @media (max-width: 1100px) {
    min-width: 70px;
    margin-right: 0;
  }

  @media (max-width: 768px) {
    padding-top: 20px;
    padding-left: 20px;
    border-left: 0 solid lightgray;
    padding-bottom: 70px;
    border-top: 1px solid lightgray;
    flex: 1 1 100%; /* 1 column */
  }
`;

const TextOverlay = styled.div`
  font-family: 'Poppins-SemiBold', Arial, sans-serif;
  font-size: 45px;
  line-height: 50px;
  color: #0A143D;
`;

const SubText = styled.div`
  font-size: 20px;
  padding-top: 10px;
  font-family: 'Poppins', Arial, sans-serif;
  width: 300px;
`;

const AdditionalText = styled.div`
  font-size: 16px;
  font-family: 'Poppins', Arial, sans-serif;
  width: 200px;
`;

const ImageIcon = styled.img`
  width: 128px;
  height: auto;

  @media (max-width: 768px) {
    padding-left: 0;
    padding-top: 50px;
      padding-bottom: 20px;
      margin-right: 0;
  }
`;

const ImageOverlay = styled.div`
  // Your styles here
`;

const TextGreen = styled.div`
  font-weight: bold;
  font-size: 40px;
  width: 100px;
  line-height: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: 'Poppins', Arial, sans-serif;
  color: #148505;
  @media (max-width: 768px) {
    padding-top: 0;
  }
`;

const LearnMoreButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  min-width: 150px;
  margin-top: 20px;
  border: 2px solid ${props => props.color};
  color: ${props => props.color};
  background: none;
  cursor: pointer;
  font-family: 'Poppins', Arial, sans-serif;
  text-transform: uppercase;
  font-size: 14px;

  &::after {
    content: ' ➔';
    display: inline-block;
    margin-left: 5px;
  }

  &:hover {
    background-color: ${props => props.color};
    color: #F0F0F5;
  }
`;

const TextBlue = styled.div`
  font-weight: bold;
  font-size: 40px;
  width: 100px;
  line-height: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: 'Poppins', Arial, sans-serif;
  color: #543BE5;
  @media (max-width: 768px) {
    padding-top: 0;
  }
`;

const TextDark = styled.div`
  font-weight: bold;
  font-size: 40px;
  width: 100px;
  line-height: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: 'Poppins', Arial, sans-serif;
  color: #0A143D;
  @media (max-width: 768px) {
    padding-top: 0;
  }
`;

const handleMailTo = (prompt) => {
  const emailAddress = 'dev@databiomes.com';
  const subject = encodeURIComponent(`Databiomes Information Request: ${prompt}`);
  const body = encodeURIComponent('Company Name: \nContact Name: \nPosition: \n---------\nProvide optional details here:\n');
  const mailtoLink = `mailto:${emailAddress}?subject=${subject}&body=${body}`;

  window.location.href = mailtoLink;
};

const LearnMore = ({ prompt, color }) => (
  <LearnMoreButton
    color={color}
    // onClick={() => handleMailTo(prompt)}
  >
    Coming Soon
  </LearnMoreButton>
);

const MainContent = () => {
  const [isVisible, elementRef] = useIntersectionObserver({
    threshold: 0.1, // Adjust the threshold as needed
  });

  return (
    <MainContainer ref={elementRef} isVisible={isVisible} id={"ServiceID"}>
      <InitContainer>
        <TextOverlay>
          A Platform For Creating Nano AI Agents
        </TextOverlay>
        <SubText>
          Customizable and optimized out of the box.
        </SubText>
      </InitContainer>
      <TextContainer>
        <ImageOverlay>
          <ImageIcon src={iconGreenImage}/>
        </ImageOverlay>
        <TextGreen>
          data bloom
        </TextGreen>
        <AdditionalText>
          A Python library for formatting AI Agent JSON files automatically.
        </AdditionalText>
        <LearnMore prompt={"Data Bloom"} color={"#148505"} />
      </TextContainer>
      <TextContainer>
        <ImageOverlay>
          <ImageIcon src={iconBlueImage}/>
        </ImageOverlay>
        <TextBlue>
          data cultivate
        </TextBlue>
        <AdditionalText>
          An API for end-to-end AI Agent model training and optimization.
        </AdditionalText>
        <LearnMore prompt={"Data Cultivate"} color={"#543BE5"} />
      </TextContainer>
      <TextContainer>
        <ImageOverlay>
          <ImageIcon src={iconDarkImage}/>
        </ImageOverlay>
        <TextDark>
          data biome
        </TextDark>
        <AdditionalText>
          A C++ inference engine for running AI agents locally in real-time.
        </AdditionalText>
        <LearnMore prompt={"Data Biome"} color={"#0A143D"} />
      </TextContainer>
    </MainContainer>
  );
};

export default MainContent;


