import React from 'react';
import styled from 'styled-components';
import backImage from '../assets/bg_dark_filled.png';
import logoImage from "../assets/top_logo.png";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 200px;
  position: relative;
  font-family: 'Poppins', Arial, sans-serif;
    @media (max-width: 768px) {
    align-items: center;
  }
`;

const AdditionalText = styled.div`
  color: #F0F0F5;
    margin-top: 30px;
    margin-left: 100px;
  font-family: 'Poppins', Arial, sans-serif;
  font-size: 18px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 14px;
      margin-left: 0;
  }
`;

const LogoImage = styled.img`
  margin-top: 60px;
    margin-left: 100px;
  width: 250px;
  @media (max-width: 768px) {
    width: 175px;
      margin-left: 0;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

const MainContent = () => {
  return (
    <MainContainer id={"UseCaseID"}>
      <BackgroundImage src={backImage} alt="Background" />
      <LogoImage src={logoImage} alt="Logo"/>
      <AdditionalText>© 2025 Databiomes Inc, All rights reserved</AdditionalText>
    </MainContainer>
  );
};

export default MainContent;

