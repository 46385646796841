import React from 'react';
import styled from 'styled-components';
import sampleVideo from '../assets/landing_bloom.mp4';

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  position: relative;
  font-family: 'Poppins', Arial, sans-serif;
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

const TextOverlay = styled.div`
    color: #F0F0F5;
    font-family: 'Poppins-Light', Arial, sans-serif;
    font-size: 72px;
    max-width: 1100px;
    padding: 20px 25% 20px 100px;
    text-align: left;
    
    @media (max-width: 1024px) {
        font-size: 56px;
        padding-left: 40px;
    }

    @media (max-width: 768px) {
        font-size: 48px;
        padding-left: 40px;
        margin-right: 40px;
        padding-right: 0;
    }
`;

const MainContent = () => (
  <MainContainer>
    <VideoBackground autoPlay loop muted playsInline>
      <source src={sampleVideo} type="video/mp4" />
    </VideoBackground>
    <TextOverlay>
      Local AI Agents for Every Application and Every Device
    </TextOverlay>
  </MainContainer>
);

export default MainContent;
