import React from 'react';
import Header from './components/Header';
import MainContent from './components/MainContent';
import LogoSplash from './components/LogoSplash';
import About from './components/About';
import What from './components/What';
import Contact from './components/Contact';
import UseCase from './components/UseCase';
import Footer from './components/Footer';
import Flora from './components/Flora';
import FloraPlus from './components/FloraPlus';
import Services from './components/Services';
import GlobalStyle from './globalStyles';

function App() {
  return (
    <div className="App">
      <GlobalStyle />
      <MainContent />
      <About />
      <What />
      <Flora />
      <FloraPlus />
      <UseCase />
        {/*<Contact />*/}
        <Footer />
      <Header />
    </div>
  );
}

export default App;

