import React from 'react';
import styled from 'styled-components';
import backImage from '../assets/bg_grey_filled.png';
import sideImage from '../assets/flora_logo.png';
import useIntersectionObserver from '../components/Hook';


const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  min-height: 100vh;
  position: relative;
  font-family: 'Poppins', Arial, sans-serif;
  flex-direction: row; /* Default layout with image on the left */
  
  @media (max-width: 768px) {
      padding-top: 100px;
    padding-bottom: 100px;
    flex-direction: column; /* Stack image on top when screen is smaller */
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 1s ease-in-out;
`;

const TextOverlay = styled.div`
  color: #0A143D;
  font-family: 'Poppins-SemiBold', Arial, sans-serif;
  font-size: 42px;
  padding: 20px 15% 20px 100px;
  text-align: left;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 1s ease-in-out;

  @media (max-width: 768px) {
    font-size: 24px;
    padding-left: 40px;
    margin-right: 40px;
    padding-right: 0;
  }
`;

const SubtitleText = styled.div`
  color: #0A143D;
  font-family: 'Poppins', Arial, sans-serif;
  font-size: 32px;
  padding: 0 20px 20px 100px;
  text-align: left;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 1s ease-in-out;

  @media (max-width: 768px) {
    font-size: 20px;
    padding-left: 40px;
    margin-top: -40px;
  }
`;

const AdditionalText = styled.div`
  color: #0A143D;
  font-family: 'Poppins', Arial, sans-serif;
  font-size: 24px;
  padding: 0 25% 20px 100px;
  text-align: left;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 1s ease-in-out;

  @media (max-width: 768px) {
    font-size: 16px;
    padding-left: 40px;
    margin-right: 40px;
    padding-right: 0;
  }
`;

const ColoredText = styled.span`
  color: #148505;
`;

const SideImage = styled.img`
  width: 325px;
  height: auto;
  padding-left: 100px;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity 1s ease-in-out;

  @media (max-width: 768px) {
    padding-left: 30px;
    padding-top: 125px;
      padding-bottom: 50px;
  }
`;

const LearnMoreButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  min-width: 150px;
  margin-top: 20px;
    margin-left: 100px;
  border: 2px solid ${props => props.color};
  color: ${props => props.color};
  background: none;
  cursor: pointer;
  font-family: 'Poppins', Arial, sans-serif;
  text-transform: uppercase;
  font-size: 14px;
    
  @media (max-width: 768px) {
    margin-left: 40px;
  }

  &::after {
    content: ' ➔';
    display: inline-block;
    margin-left: 5px;
  }

  &:hover {
    background-color: ${props => props.color};
    color: #F0F0F5;
  }
`;

const handleMailTo = (prompt) => {
  const emailAddress = 'dev@databiomes.com';
  const subject = encodeURIComponent(`Databiomes Information Request: ${prompt}`);
  const body = encodeURIComponent('Company Name: \nContact Name: \nPosition: \n---------\nProvide optional details here:\n');
  const mailtoLink = `mailto:${emailAddress}?subject=${subject}&body=${body}`;

  window.location.href = mailtoLink;
};

const LearnMore = ({ prompt, color }) => (
  <LearnMoreButton
    color={color}
    onClick={() => handleMailTo(prompt)}
  >
    Learn More
  </LearnMoreButton>
);

const MainContent = () => {
    const [isVisible, elementRef] = useIntersectionObserver({
    threshold: 0.1, // Adjust the threshold as needed
  });

  return (
  <MainContainer id={"FloraID"}>
    <BackgroundImage src={backImage} alt="Background" />

    <TextContainer ref={elementRef} isVisible={isVisible}>
        <SideImage src={sideImage} alt="Side" ref={elementRef} isVisible={isVisible}/>
        <SubtitleText ref={elementRef} isVisible={isVisible}>
        Specialized Nano Language Model.
    </SubtitleText>
    <TextOverlay ref={elementRef} isVisible={isVisible}>
        The 'nano' size of <ColoredText>40 MB</ColoredText> that runs <ColoredText>100% local.</ColoredText> No lag, no cloud, and no data sharing.
    </TextOverlay>
<AdditionalText ref={elementRef} isVisible={isVisible}>
        Flora is ultra-efficient and runs entirely on CPU or NPU in real-time. We can be this efficient when using intelligent data and a custom inference engine.
    </AdditionalText>
    <LearnMore prompt={"Flora"} color={"#0A143D"} />
    </TextContainer>
  </MainContainer>
);};

export default MainContent;

